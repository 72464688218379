import React from 'react';
import Header from '../Header'

export default class Awards extends React.Component{


render(){
    return(
        <div>
            <Header></Header>
        <h1> Awards </h1>
        <h2>Coming Soon...</h2>
        </div>
    )
}
}
