import React from 'react';

export default class Footer extends React.Component{

    render(){
        return(
            <div>
                <h2>Footer</h2>
            </div>
        )
    }
}